@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'font1';
    src: url('/assets/fonts/madefor-text.var.original\ \(1\).woff2');
}
@font-face {
    font-family: 'font2';
    src: url('/assets/fonts/proxima-n-w01-reg.woff2');
}
@font-face {
    font-family: 'font3';
    src: url('/assets/fonts/madefor-text.var.original\ \(1\).woff2');
}
body{
    
    overflow-x: hidden;
    background: #EDEBEA;
}
#main{
    overflow-x: hidden !important;
}


.font1 {
    font-family: 'font1';
}
.font2 {
    font-family: 'font2';
}

/* Color classes */
.gray-color{
    color: rgb(72, 68, 82);
}
.gradient {
    background-image: linear-gradient(
      45deg,
      hsl(0deg 0% 100%) 0%,
      hsl(344deg 0% 98%) 8%,
      hsl(344deg 0% 97%) 17%,
      hsl(344deg 0% 95%) 25%,
      hsl(344deg 0% 93%) 33%,
      hsl(344deg 0% 92%) 42%,
      hsl(344deg 0% 90%) 50%,
      hsl(344deg 0% 88%) 58%,
      hsl(344deg 0% 87%) 67%,
      hsl(344deg 0% 85%) 75%,
      hsl(344deg 0% 83%) 83%,
      hsl(344deg 0% 82%) 92%,
      hsl(0deg 0% 80%) 100%
    );
  }

  .active{
    color: #eb7641;
  }
@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}

.animate-marquee {
    animation: marquee 20s linear infinite;
}

.marquee-container {
    display: flex;
    width: fit-content;
}

.marquee-content {
    display: flex;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
}

/* Optional: Pause animation on hover */
.marquee-container:hover .marquee-content {
    animation-play-state: paused;
}